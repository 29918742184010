import {
  defineModule,
  localActionContext,
  localGetterContext,
  defineActions,
} from "direct-vuex";
/*import mutations from "./mutations";
import getters from "./getters";*/

export const appStateModuleId = "app";

//export interface AppState {}

//export const initialAppState: AppState = {};

const appStateModule = defineModule({
  /*state: (): AppState => {
    return initialAppState;
  },
  mutations,
  getters,*/
  actions: defineActions({}),
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const appStateModuleActionContext = (context: any) =>
  localActionContext(context, appStateModule);

export const appStateModuleGetterContext = (args: [any, any, any, any]) =>
  localGetterContext(args, appStateModule);
/* eslint-enable @typescript-eslint/no-explicit-any */

export default appStateModule;
